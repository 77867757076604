<template>
    <div class="builder-input" v-html="previewObj.html">
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';

export default {
    name: 'landingPagePreviewV2',
    props:['previewObj'],
    data() {
        return {
            height: 650,
        }
    },
    created() {
        //this.checkCSSAndForms()
    },
    mounted() {
        this.checkCSSAndForms()
    },
    watch: {
        
    },
    computed :{
        
    },
    methods: {
        checkCSSAndForms(){
            let css = this.previewObj.css;
            if(css){
                if(css.backgroundColor){
                    document.getElementsByClassName('builder-input')[0].style.backgroundColor = css.backgroundColor;
                }
                if(css.containerBackgroundColor)
                    document.getElementsByClassName('contentbuildercontainer')[0].style.backgroundColor = css.containerBackgroundColor;

                if(css.maxWidth){
                    document.getElementsByClassName('builder-input')[0].style.maxWidth = css.maxWidth;
                }
                if(css.morecss && css.morecss != 'undefined'){
                    document.getElementsByClassName('builder-input')[0].insertAdjacentHTML('afterbegin', "<style>"+css.morecss+"</style>")
                }
            }

            let formsScript = document.getElementsByClassName('survey-script');
            if(formsScript){
                formsScript.forEach(element => {
                    console.log(element)
                    console.log(element.parentElement)
                    let parentElementTest = element.parentElement;
                    let dataId = element.getAttribute('data-id');
                    var sc = document.createElement("script");
                    sc.setAttribute("src", element.src);
                    sc.setAttribute("data-id", dataId);
                    sc.setAttribute("class", "survey-script");
                    //document.getElementById('formContainer_{id}').appendChild(sc);
                    element.remove()
                    parentElementTest.appendChild(sc)
                    //element.src = element.src
                });
            }
            
            console.log(formsScript)
        }
    },
}
</script>
<style lang="scss">
    
</style>