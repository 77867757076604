<template>
    <div style="height:calc(100%);">
        <div class="mb-6">
            <div class="d-flex align-center mb-1">
                <v-badge class="mt-1 ml-2 mr-6" :color="previewObj.draft == 1 ? 'grey' : 'success'" dot></v-badge>
                <h2 class="mb-0" style="font-size:24px; line-height:24px;">{{ previewObj.name }}</h2>
            </div>
            <p class="mb-1" v-html="previewObj.description"></p>
        </div>
        <v-row class="editorPreview" style=" border:1px solid #CCC;">
            <v-col cols="12" class="pa-0 d-flex justify-center">
                <iframe id="landingPageIFramePreview" name="myFrame" frameborder="0" :height="height - 10" :class="isMobile ? 'iframeHtml mobile' : 'iframeHtml'" ref="iframeHtml"></iframe>
            </v-col>

        </v-row>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';

export default {
    name: 'landingPagePreview',
    props:['landing', 'previewObj'],
    data() {
        return {
            urlLanding: "https://testuyb.evt-auto.fr/?edition_mode=1&step=0",
            isMobile: false,
            height: 650,
            landingString: "",
        }
    },
    created() {
        if(this.landing.currentLanding && this.landing.css)
            this.refreshFrame();
    },
    mounted() {
        if(this.landing.currentLanding && this.landing.css)
            this.refreshFrame();
    },
    watch: {
        landing(val){
            if(val && val.css && val.currentLanding)
                this.refreshFrame();
        }
    },
    computed :{
        computedSteps(){
            let steps = this._.cloneDeep(this.landing.currentLanding.hooks.lp_form.steps)

            if(this.landing.currentLanding.settings.places.length < 2){
                delete(steps.places)
            }
            if(!this.landing.currentLanding.settings.options.resources){
                delete(steps.resources)
            }
            if(this.landing.currentLanding.settings.options.meeting_type != "RDV"){
                delete(steps.slots)
            }
            if(this.landing.currentLanding.settings.number_of_days < 2){
                delete(steps.calendar)
            }

            return Object.keys(steps);
        },
    },
    methods: {
        refreshFrame(){
            let landingToSend = this._.cloneDeep(this.landing.currentLanding);

            //reencode les text
            for(const property in landingToSend.hooks) {
                if (Object.hasOwnProperty.call(landingToSend.hooks, property)) {
                    const hook = landingToSend.hooks[property];
                    if(hook.elements){
                        for (const key in hook.elements) {
                            if (Object.hasOwnProperty.call(hook.elements, key)) {
                                const element = hook.elements[key];
                                if(element.type == 'textarea'){
                                    element.value = this.encodeHTML(element.value)
                                }
                            }
                        }
                    }
                }
            }
            this.computedSteps.forEach(element => {
                if(landingToSend.hooks.lp_form.steps[element].text){
                    landingToSend.hooks.lp_form.steps[element].text = this.decodeHtml(landingToSend.hooks.lp_form.steps[element].text)
                }
            });

            this.landingString = JSON.stringify(landingToSend)
            GenericDataService.postData(this.urlLanding, {css : this.landingString}).then((response) => {
                this.$refs.iframeHtml.contentDocument.open()
                this.$refs.iframeHtml.contentDocument.write(response.data);
                this.$refs.iframeHtml.contentDocument.close()
            })
            
        },
        encodeHTML(string){
            return string.replace(/&/g, '&amp;')
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;')
               .replace(/"/g, '&quot;')
               .replace(/'/g, '&apos;')
               .replace(/[\n\r]/g,' ');
        },
        decodeHtml(string){
            return string.replace(/&apos;/g, "'")
               .replace(/&quot;/g, '"')
               .replace(/&gt;/g, '>')
               .replace(/&lt;/g, '<')
               .replace(/&amp;/g, '&');
        },
        shadeColor(color, mode) {
            let percent = 0;
            switch (mode) {
                case 'lighter':
                    percent = 20
                    break;
                case 'darker':
                    percent = -20
                    break;
            }
            var R = parseInt(color.substring(1,3),16);
            var G = parseInt(color.substring(3,5),16);
            var B = parseInt(color.substring(5,7),16);

            R = parseInt(R * (100 + percent) / 100);
            G = parseInt(G * (100 + percent) / 100);
            B = parseInt(B * (100 + percent) / 100);

            R = (R<255)?R:255;  
            G = (G<255)?G:255;  
            B = (B<255)?B:255;  

            var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
            var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
            var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

            return "#"+RR+GG+BB;
        },
    },
}
</script>
<style lang="scss">
    
</style>