<template>
    <div>
        <div class="mb-6">
            <div class="d-flex align-center mb-1">
                <v-badge class="mt-1 ml-2 mr-6" :color="previewObj.draft == 1 ? 'grey' : 'success'" dot></v-badge>
                <h2 class="mb-0" style="font-size:24px; line-height:24px;">{{ previewObj.name }}</h2>
            </div>
            <p class="mb-1" v-html="previewObj.description"></p>
        </div>
        <h5 class="ml-2 mb-2">{{ $t('entitiesRepartition') }}</h5>
        <DonutComponent :key="donutKey" :dataSet="graphData" :legend="false" class="px-4" style="width:100%;"/>
        <div class="legend mr-6">
            <div v-for="(legend, index) in labels('entities')" :key="'legend_' + index">
                <span :style="'width: 20px; height:10px; display:inline-block; background-color:' + backgroundRefs[index]"></span> {{legend}}
            </div>
        </div>
    </div>
</template>
<script>
import DonutComponent from '@/components/graphs/DonutComponent'

export default {
    name: "SegmentPreview",
    components:{DonutComponent},
    props: ['realTime', 'previewObj'],
    data() {
        return {
            backgroundRefs: ["#FFEBCD","#0000FF","#8A2BE2","#A52A2A","#DEB887","#5F9EA0","#7FFF00","#D2691E","#FF7F50","#6495ED","#FFF8DC","#DC143C","#00FFFF","#00008B","#008B8B","#B8860B","#A9A9A9","#006400","#BDB76B","#8B008B","#556B2F","#FF8C00","#9932CC","#8B0000","#E9967A","#8FBC8F","#483D8B","#2F4F4F","#00CED1","#9400D3","#FF1493","#00BFFF","#696969","#1E90FF","#B22222","#FFFAF0","#228B22","#FF00FF","#DCDCDC","#F8F8FF","#FFD700","#DAA520","#ADFF2F","#F0FFF0","#FF69B4","#CD5C5C","#4B0082","#FFFFF0","#F0E68C","#E6E6FA","#FFF0F5","#7CFC00","#FFFACD","#ADD8E6","#F08080","#E0FFFF","#FAFAD2","#90EE90","#D3D3D3","#FFB6C1","#FFA07A","#20B2AA","#87CEFA","#778899","#B0C4DE","#FFFFE0","#00FF00","#32CD32","#FAF0E6","#FF00FF","#66CDAA","#0000CD","#BA55D3","#9370DB","#3CB371","#7B68EE","#00FA9A","#48D1CC","#C71585","#191970","#F5FFFA","#FFE4E1","#FFE4B5","#FFDEAD","#000080","#FDF5E6","#808000","#6B8E23","#FFA500","#FF4500","#DA70D6","#EEE8AA","#98FB98","#AFEEEE","#DB7093","#FFEFD5","#FFDAB9","#CD853F","#FFC0CB","#DDA0DD","#B0E0E6","#FF0000","#BC8F8F","#4169E1","#8B4513","#FA8072","#F4A460","#2E8B57","#FFF5EE","#A0522D","#C0C0C0","#87CEEB","#6A5ACD","#708090","#FFFAFA","#00FF7F","#4682B4","#D2B48C","#008080","#D8BFD8","#FF6347","#40E0D0","#EE82EE","#F5DEB3","#FFFFFF","#F5F5F5","#FFFF00","#9ACD32","#F0F8FF","#FAEBD7","#00FFFF","#7FFFD4","#F0FFFF","#F5F5DC","#FFE4C4","#000000"],
            donutKey:0,
        }
    },
    watch: {
        graphData(val){
            this.donutKey++
        }
    },
    computed: {
        graphData() {
            let graphData = {}
            if(this.realTime && this.realTime.aggregations && this.realTime.aggregations['entities']){
                graphData.datasets = [];
                let dataset = {};
                dataset.label = "";
                dataset.data = Object.values(this.realTime.aggregations['entities']).map(function (obj) { return obj.value });
                dataset.backgroundColor =  this.backgroundRefs;
                graphData.datasets.push(dataset);
                graphData.labels = this.labels('entities');
            }
            
            return graphData;
        },
    },
    methods: {
        labels(type) {
            return Object.values(this.realTime.aggregations[type]).map(function (obj) { return obj.to_trad ? this.$t(obj.label) : obj.label; }, this);
        },
    },
}
</script>
<style lang="scss">
    
</style>